import { useState } from 'react';
import Clock from "react-clock"

import { randomIntFromInterval } from './util';

import './App.scss';


function App() {
  const [minuteSetting, setMinuteSetting] = useState("60");
  const [time, setTime] = useState();
  const [showTime, setShowTime] = useState(false);
  const [timeString, setTimeString] = useState("");

  const minuteValues = {
    "30": [0, 30],
    "15": [0, 15, 30, 45],
    "05": [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55]
  }

  const minuteSelectOptions = [
    { label: "Hele uren",  value: "60" },
    { label: "Halve uren", value: "30" },
    { label: "Kwartieren", value: "15" },
    { label: "5 minuten",  value: "05" }
  ]



  const setNewTime = () => {
    const newTime = new Date()
    newTime.setSeconds(0)
    newTime.setHours(randomIntFromInterval(1, 12))
    
    switch (minuteSetting) {
      case "60": newTime.setMinutes(0);  break;
      case "30": newTime.setMinutes(minuteValues["30"][Math.floor(Math.random() * minuteValues["30"].length)]); break;
      case "15": newTime.setMinutes(minuteValues["15"][Math.floor(Math.random() * minuteValues["15"].length)]); break;
      case "05": newTime.setMinutes(minuteValues["05"][Math.floor(Math.random() * minuteValues["05"].length)]); break;
      default: break;
    }

    let timeStringHour = newTime.getMinutes() > 15 ? newTime.getHours() + 1 : newTime.getHours()
    if (timeStringHour === 13) timeStringHour = timeStringHour - 12

    switch (timeStringHour) {
      case 1: timeStringHour = "een"; break;
      case 2: timeStringHour = "twee"; break;
      case 3: timeStringHour = "drie"; break;
      case 4: timeStringHour = "vier"; break;
      case 5: timeStringHour = "vijf"; break;
      case 6: timeStringHour = "zes"; break;
      case 7: timeStringHour = "zeven"; break;
      case 8: timeStringHour = "acht"; break;
      case 9: timeStringHour = "negen"; break;
      case 10: timeStringHour = "tien"; break;
      case 11: timeStringHour = "elf"; break;
      case 12: timeStringHour = "twaalf"; break;
      default: break;
    }

    let timeStringPrefix = "",
        timeStringSuffix = ""
    switch (newTime.getMinutes()) {
      case 0:  timeStringSuffix = " uur"; break;
      case 5:  timeStringPrefix = "vijf over "; break;
      case 10: timeStringPrefix = "tien over "; break;
      case 15: timeStringPrefix = "kwart over "; break;
      case 20: timeStringPrefix = "tien voor half "; break;
      case 25: timeStringPrefix = "vijf voor half "; break;
      case 30: timeStringPrefix = "half "; break;
      case 35: timeStringPrefix = "vijf over half "; break;
      case 40: timeStringPrefix = "tien over half "; break;
      case 45: timeStringPrefix = "kwart voor "; break;
      case 50: timeStringPrefix = "tien voor "; break;
      case 55: timeStringPrefix = "vijf voor "; break;
      default: break;
    }




    setTime(newTime)
    setShowTime(false)
    
    setTimeString(timeStringPrefix + timeStringHour.toString() + timeStringSuffix + ".")
  }

  //if(time) console.log(time.getHours(), time.getMinutes())

  return (
    <div className="layout">
      <aside className="sidebar">
        <div>
          <h1>De klok</h1>
        </div>
        <select id="minuteSetting" name="minuteSetting" value={minuteSetting} onChange={ (e)=>{ setMinuteSetting(e.target.value) } }>
          { minuteSelectOptions.map(option => <option key={ option.value } value={ option.value }>{ option.label }</option>) }
        </select>
        <button onClick={()=>{ setNewTime() }}>Nieuwe tijd</button>
      </aside>
      <main>
        <div className="clock-container">
          <h3 className="clock-title">NT2 klok - Dinthe</h3>
          <Clock
            value={time} 
            size={500} 
            renderNumbers={true} 
            renderSecondHand={false} 
            hourHandWidth={7}
            hourHandOppositeLength={7}
            minuteHandWidth={4}
            minuteHandOppositeLength={7}
          />
        </div>
        <div className="show-time">
          { timeString && showTime && <h2>Het is { timeString }</h2> }
          { timeString && !showTime && <button onClick={ ()=> { setShowTime(true) } }>Hoe laat is het?</button> }
        </div>
      </main>

      
    </div>
  )
}

export default App;